import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'

import { makeStyles } from '@material-ui/core/styles'

import MetaTags from '@objects/metatags'
import Container from '@objects/container'
import Button from '@objects/button'
import Headline from '@objects/headline'
import Paragraph from '@objects/paragraph'
import { Theme, useMediaQuery, useTheme, Grid } from '@material-ui/core'
import { CSSProperties } from '@material-ui/core/styles/withStyles'

interface IRootIndex {
  data: Record<string, unknown>
  pageContext: Record<string, string>
  params: {
    id: string
  }
}

const useStyles = makeStyles(
  (theme: Theme): Record<string, CSSProperties> => ({
    container: {
      padding: theme.spacing(4, 0, 12, 0),
      marginBottom: 0,
      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(8, 0, 16, 0),
      },
    },
    imageContainer: {
      marginBottom: 0,
      [theme.breakpoints.down('sm')]: {
        padding: 0,
      },
    },
    imageWrapper: {
      position: 'relative',
      width: '100%',
      paddingTop: '70.72916%',
      backgroundColor: theme.palette.grey[500],
    },
    image: {
      display: 'block',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
    },
    headline: {
      color: theme.palette.text.invert,
    },
    button: {
      width: '100%',
    },
    paragraph: {
      color: theme.palette.text.invert,
    },
  })
)

export default function PlakatePage({
  pageContext,
  params,
}: IRootIndex): React.ReactElement {
  const classes = useStyles()
  const theme = useTheme()
  const isMediumUp = useMediaQuery(theme.breakpoints.up('md'))

  const [imageState, setImageState] = useState<'error' | 'loading' | 'success'>(
    'loading'
  )

  function imageLoaded() {
    setImageState('success')
  }

  function imageError() {
    setImageState('error')
  }

  return (
    <React.Fragment>
      <MetaTags locale={pageContext.locale} meta={{ noIndex: true }} />

      <Container
        className={classes.imageContainer}
        background={isMediumUp ? 'greyDark' : undefined}
        type="section"
      >
        <div className={classes.imageWrapper}>
          {!!params.id && (
            <img
              className={classes.image}
              src={`${process.env.GATSBY_PLAKATGENERATOR_API}/api/plakatgenerator/Storage/${params.id}/download`}
              onLoad={imageLoaded}
              onError={imageError}
            />
          )}
        </div>
      </Container>
      <Container
        className={classes.container}
        background="greyDark"
        padded
        type="section"
      >
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Grid item xs={12} md="auto">
            <Headline level={2} className={classes.headline}>
              <FormattedMessage id="plakate.headline" />
            </Headline>
          </Grid>

          {imageState === 'success' && (
            <Grid item xs={12} md="auto">
              <Button
                className={classes.button}
                type="tertiary"
                icon="Download"
                iconPosition="right"
                isExternal
                hideLinkIcon
                to={`${process.env.GATSBY_PLAKATGENERATOR_API}/api/plakatgenerator/Storage/${params.id}/download`}
              >
                <FormattedMessage id="plakate.button.download" />
              </Button>
            </Grid>
          )}
          {imageState === 'error' && (
            <Grid item xs={12}>
              <Paragraph className={classes.paragraph}>
                <FormattedMessage id="plakate.errormessage" />
              </Paragraph>
            </Grid>
          )}
        </Grid>
      </Container>
    </React.Fragment>
  )
}
